@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--adjusted-padding: 1.5rem;
}

@layer icons {
	.icon--small .svg-inline--fa {
		height: 1em;
	}
	
	.icon--medium .svg-inline--fa {
		height: 1.5em;
	}
	
	.icon--large .svg-inline--fa {
		height: 2em;
	}
}

@layer pdf {
	.k-pdf-export .pdf-hide {
		display: none;
	}

	.k-pdf-export .questionTable {
		@apply relative min-h-[3.5em];
	}

	.k-pdf-export .questionTable-identifier {
		@apply absolute top-4;
	}

	.k-pdf-export .questionTable-main {
		transform: translateX(11.111111%);
	}

	.k-pdf-export .questionTable-yesnomain {
		transform: translateX(9%);
	}

	.k-pdf-export strong {
		display: inline;
		font-weight: bold;
	}

	.k-pdf-export em {
		display: inline;
		font-style: italic;
	}

	.k-pdf-export span {
		display: inline;
	}

	.k-pdf-export .parsed-content {
		@apply pr-4;
	}

	.k-pdf-export .parsed-content__inner {
		@apply pr-4;
	}
}

@layer components {
	.datepicker-icon {
		min-width: 8.5em;
		padding-right: 32px;
		background-image: url('/images/calendar.svg');
		background-repeat: no-repeat;
		background-position: calc(100% - 12px) 50%;
	}

	.signature-icon {
		padding-left: 48px;
		background-image: url('/images/pen.svg');
		background-repeat: no-repeat;
		background-position: 12px 50%;
	}

	.input-currency {
		display: block;
		position: relative;
	}

	.input-currency input {
		@apply pl-5
	}

	.input-currency::before {
		content: '$ ';
		position: absolute;
		left: 8px;
		top: 50%;
		transform: translateY(-50%);
		color: #999;
	}

	.sr-only:not(:focus):not(:active) {
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}

	.breadcrumb::after {
		@apply inline-block text-rural-gray-500 text-xs font-normal mr-2 ml-4;
		content: ' \20eb ';
		transform: translateY(-0.25em);
	}

	.drawer-content {
		@apply fixed z-30 w-[95%] h-full max-w-[800px] p-4 bg-white overflow-auto;
		transition: transform 0.3s ease-in-out;
	}

	.drawer-content--embedded {
		@apply max-w-[1100px];
	}

	.drawer-content.top {
		@apply top-0 right-0 left-0 w-full h-[40%];
		transform: translateY(-100%);
	}

	.drawer-content.right {
		@apply top-0 right-0;
		transform: translateX(100%);
	}

	.drawer-content.bottom {
		@apply right-0 bottom-0 left-0 w-full h-[40%];
		transform: translateY(100%);
	}

	.drawer-content.left {
		@apply top-0 left-0;
		transform: translateX(-100%);
	}

	.drawer--open.drawer--in .drawer-content {
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
	}

	.drawer--open.drawer--in .left, .drawer--open.drawer--in .right {
		transform: translateX(0);
	}

	.drawer--open.drawer--in .top, .drawer--open.drawer--in .bottom {
		transform: translateY(0);
	}

	.drawer-backdrop {
		@apply fixed top-0 left-0 w-full h-full bg-gray-overlay-800 invisible opacity-0 pointer-events-none z-0;
		transition: opacity 0.3s ease, visibility 0.3s ease;
	}

	.drawer-backdrop--active {
		@apply visible opacity-100 pointer-events-auto z-20;
	}

	.striped {
		background-image: repeating-linear-gradient(
			135deg,
			transparent,
			transparent 45px,
			rgba(0, 0, 0, 0.07) 45px,
			rgba(0, 0, 0, 0.07) 90px
		),
		linear-gradient(
			to bottom,
			rgba( 255, 255, 255, 0.2 ),
			rgba( 0, 0, 0, 0.2 )
		);
	}

	.parsed-content p {
		@apply my-2;
	}

	.parsed-content ul {
		@apply list-disc ml-4 my-2;
	}

	.parsed-content ol {
		@apply list-decimal ml-4 my-2;
	}

	.pasred-content h1, .parsed-content h2, .parsed-content h3, .parsed-content h4, .parsed-content h5, .parsed-content h6 {
		@apply mt-4 text-lg font-bold;
	}

	.k-pdf-export .parsed-content span {
		display: inline-block;
	}

	.k-pdf-export .parsed-content strong {
		display: inline;
	}

	.k-pdf-export .section-description {
		max-width: 95%;
	}

	.overflow-word {
		word-break: break-word;
	}
}

#__next {
	min-height: 100%;
}

html, body {
	padding: 0;
	margin: 0;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

/* elements added in Squidex. Id is the section title */
#disclaimer {
	margin-top:-40px;
}
#disclaimer h3 {
	display:none;
}
#disclaimer p {
	font-style: italic;
}
/******/

/* PDF styles: pdf container gets .k-pdf-export class added during render */
.k-pdf-export textarea {
	border:none;
	padding:0;
}

/*
	Faux table styles used by multi item tables so we can use grid layout
	instead of table layout because of issues with PDF exports
*/
.faux-table {
	display: grid;
}

.faux-tr {
	display: contents;
}

.faux-tr--subrow {
	display: unset;
	grid-column: 1/-1;
}

.faux-tfoot, .faux-table .faux-table {
	width: 100%;
	grid-column: 1/-1;
}
